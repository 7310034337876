import { Avatar, Chip, Grid, LIcon, Tooltip, Typography } from '@l_ine/core';
import { lineCommentLines } from '@l_ine/solid-svg-icons';
import { colorPalette } from '@l_ine/tokens';
import moment from 'moment';
import { NpsPayload } from 'redux/results/types';
import ConteudoTooltip from '../conteudoTootip/conteudoTooltip';
import NaoRespondido from '../naoRespondido/naoRespondido';

type NPSContentProps = Pick<
  NpsPayload,
  | 'nota'
  | 'razaoSocial'
  | 'nomeFantasia'
  | 'tipoPessoa'
  | 'dataAvaliacao'
  | 'quantidadeDisparos'
  | 'quantidadeCliques'
  | 'nomeUsu'
  | 'emailUsu'
  | 'comentario'
> & { index: number };

const NPSContent: React.FC<NPSContentProps> = ({
  nota,
  index,
  nomeUsu,
  emailUsu,
  comentario,
  tipoPessoa,
  razaoSocial,
  nomeFantasia,
  dataAvaliacao,
  quantidadeCliques,
  quantidadeDisparos,
}) => (
  <Grid container spacing={1} style={{ marginBottom: '0.3em' }}>
    <Grid item xs={12} style={{ display: 'flex' }}>
      <Grid container>
        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: nota || nota === 0 ? '100%' : '50%',
          }}
        >
          <Avatar
            children={razaoSocial}
            size='md'
            style={{ marginRight: '0.5em' }}
            data-testid={`resultados--nps--modal-nps--listItem[${index}]--avatar`}
          />
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: '7px' }}>
              {((nomeFantasia && razaoSocial && nomeFantasia != razaoSocial) ||
                tipoPessoa == 'Parceiro') && (
                <>
                  {tipoPessoa == 'Parceiro' && (
                    <Tooltip label='Cliente é um parceiro'>
                      <span>
                        <Chip
                          label='Parceiro'
                          color={colorPalette.neutral[100]}
                          style={{
                            color: colorPalette.neutral[0],
                            position: 'relative',
                            zIndex: 20,
                            width: '3.8em',
                            height: '2.2em',
                          }}
                          size='sm'
                          data-testid={`resultados--nps--modal-nps--listItem[${index}]--chip-parceiro`}
                        />
                      </span>
                    </Tooltip>
                  )}
                  {nomeFantasia && razaoSocial && nomeFantasia != razaoSocial && (
                    <Typography
                      variant='titleXS'
                      style={{
                        marginLeft: tipoPessoa == 'Parceiro' ? '0.3em' : 0,
                      }}
                    >
                      {nomeFantasia.toLowerCase()}
                    </Typography>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '18px',
                }}
              >
                <Typography
                  variant='titleXS'
                  style={{ textTransform: 'capitalize' }}
                >
                  {razaoSocial
                    ? razaoSocial.toUpperCase()
                    : nomeFantasia.toUpperCase()}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ opacity: nota || nota === 0 ? '100%' : '50%' }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                margin: '0.5em 0 0.3em 0',
              }}
            >
              {nota || nota === 0 ? (
                <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
                  Avaliou em {moment(dataAvaliacao).format('DD/MM/YYYY')} às{' '}
                  {moment(dataAvaliacao).format('LT')}
                </Typography>
              ) : (
                <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
                  Apresentada {quantidadeDisparos}{' '}
                  {`${quantidadeDisparos == 1 ? 'vez' : 'vezes'}`}, último em{' '}
                  {moment(dataAvaliacao).format('DD/MM/YYYY')} às{' '}
                  {moment(dataAvaliacao).format('LT')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='titleXS'>{nomeUsu}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='bodyXS'>{emailUsu}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {nota || nota === 0 ? (
            <>
              <Grid item xs={6}>
                {comentario && (
                  <Tooltip
                    label={<Typography variant='titleXS'>Comentário</Typography>}
                    description={
                      <Grid container style={{ maxWidth: '400px' }}>
                        <Grid item xs={12}>
                          <Typography variant='bodyXS'>{comentario}</Typography>
                        </Grid>
                      </Grid>
                    }
                    inverted
                  >
                    <div>
                      <LIcon
                        icon={lineCommentLines}
                        size='24px'
                        color={colorPalette.neutral[100]}
                      />
                    </div>
                  </Tooltip>
                )}
              </Grid>
              {(nota || nota === 0) && (
                <Tooltip
                  label=' '
                  description={
                    <ConteudoTooltip
                      quantidadeCliques={quantidadeCliques}
                      quantidadeDisparos={quantidadeDisparos}
                    />
                  }
                  inverted
                >
                  <span>
                    <Chip
                      label={
                        <Typography
                          variant='titleXS'
                          color={colorPalette.neutral[0]}
                        >
                          Nota {nota}
                        </Typography>
                      }
                      color={
                        nota > 8
                          ? colorPalette.green[300]
                          : nota > 6 && nota < 9
                          ? colorPalette.neutral[100]
                          : colorPalette.red[300]
                      }
                      size='sm'
                      style={{
                        height: '2.2em',
                        width: '5em',
                        justifyContent: 'center',
                      }}
                      data-testid={`resultados--nps--modal-nps--listItem[${index}]--chip-nota`}
                    />
                  </span>
                </Tooltip>
              )}
            </>
          ) : (
            <NaoRespondido
              index={index}
              quantidadeCliques={quantidadeCliques}
              quantidadeDisparos={quantidadeDisparos}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default NPSContent;
