import * as React from 'react';
import { Section } from './redirectLink.styles';
import type { RedirectProps } from './redirectLink.types';
import useRedirectLink from './useRedirectLink';

const RedirectLink: React.FC<RedirectProps> = ({ to, children }) => {
  const { _redirecionar } = useRedirectLink(to);

  return <Section onMouseUp={_redirecionar}>{children && children}</Section>;
};

export default React.memo(RedirectLink);
