export const texts: { [name: string]: { [name: string]: string } } = {
  title: {
    interactions: 'Nenhuma interação negativa.',
    plans: 'Nenhum plano cadastrado.',
    resources: 'Nenhum recurso encontrado.',
    groups: 'Nenhum grupo encontrado.',
    partnersTypes: 'Nenhum tipo de parceiro encontrado',
    cac: 'Nenhum CAC cadastrado',
    cmc: 'Nenhum CMC cadastrado',
    support: 'Nenhum suporte cadastrado',
    risks: 'Nenhum risco encontrado',
    emails: 'Nenhum e-mail encontrado'
  },
  subtitle: {
    interactions:
      'Nessa seção serão mostrados todas as interações negativas dos clientes.',
    plans: 'Essa é a listagem de todos os planos cadastrados em seu sistema.',
    resources:
      'Essa é a listagem de todos os recursos cadastrados em seu sistema.',
    groups: 'Essa é a listagem de todos os grupos cadastrados no sistema.',
    partnersTypes:
      'Essa é a listagem de todos os tipos de parceiros cadastrados no sistema',
    cac: 'Essa é a listagem de CACs mensais',
    cmc: 'Essa é a listagem de CMCs mensais',
    support: 'Essa é a listagem de suportes mensais',
    risks: 'Nesta seção estarão os riscos relacionados aos clientes.',
  },
  button: {
    plans: 'Novo plano',
    resources: 'Novo recurso',
    groups: 'Novo grupo',
    partnersTypes: 'Novo tipo de parceiro',
    cac: 'Novo CAC',
    cmc: 'Novo CMC',
    support: 'Novo suporte',
    emails: 'Novo e-mail'
  },
};
