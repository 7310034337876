import {
  Breadcrumbs,
  Grid,
  LIcon,
  Popover,
  TextField,
  Typography,
} from '@l_ine/core';
import { lineSearch } from '@l_ine/solid-svg-icons';
import RedirectLink from 'components/redirectLink/redirectLink';
import Link from 'next/link';
import Router from 'next/router';
import React, { useCallback, useState } from 'react';
import { configurationPages } from '../../../containers/Settings/static';
import { useTypedSelector } from '../../../hooks/useTypeSelector';
import HeaderBackButton from '../../HeaderBackButton';
import * as styled from './index.style';
import type { ConfigurationsProps } from './index.types';

const Configurations: React.FC<ConfigurationsProps> = (props) => {
  const [results, setResults] = useState<any>([]);
  const [searchBox, setSearchBox] = useState<any>(null);
  const { title } = props;
  const sis_codigo = useTypedSelector((state) => state.Auth.sistema.sis_codigo);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let text = '';

      if (e) {
        text = e.target?.value;
      } else {
        setResults([]);
      }

      if (text === '') {
        setResults([]);
        return;
      }

      const resultsAcumulattor: any = [];
      configurationPages.forEach((section) => {
        const searchLinks = section.links.filter((link) => {
          if (section.key == 1 && sis_codigo !== 1) {
            return;
          }
          return link.label.toLowerCase().includes(text.toLowerCase());
        });

        if (searchLinks.length > 0) {
          resultsAcumulattor.push({ ...section, links: searchLinks });
        }
      });

      setResults(resultsAcumulattor);
    },
    []
  );

  return (
    <Grid container style={{ display: 'flex', alignItems: 'center' }}>
      <Grid item xs={8}>
        {Router.pathname !== '/config' ? (
          <Breadcrumbs>
            <a
              onClick={() => Router.back()}
              target='_self'
              style={{ cursor: 'pointer' }}
              data-testid='config--breadcrumbs-voltar'
            >
              <Typography variant='bodyXS'>Configurações</Typography>
            </a>
            <Typography variant='titleXXS'>{title ? title : '-'}</Typography>
          </Breadcrumbs>
        ) : null}
        <styled.TitleContainer>
          {title ? (
            <HeaderBackButton title={title} pathname='/config' />
          ) : (
            <Typography variant='titleMD'>Configurações</Typography>
          )}
        </styled.TitleContainer>
      </Grid>
      <styled.SearchContainer>
        <TextField
          fieldSize='md'
          placeholder='Buscar em Configurações'
          startAdornment={<LIcon icon={lineSearch} size='24px' removeMargin />}
          style={{ maxWidth: 396, width: '100%' }}
          inputProps={{
            'data-testid': 'config--field-buscar',
            onChange: handleSearch,
            onClick: (e: any) => setSearchBox(e.currentTarget),
          }}
        />
        <Popover
          anchorEl={searchBox}
          open={Boolean(searchBox) && results.length}
          onClose={() => setSearchBox(null)}
          placement='bottom'
        >
          <styled.SearchResults className='results'>
            {results.length > 0 ? (
              results.map((page: any, index: number) => (
                <styled.SearchSection key={index}>
                  <Typography variant='bodyXXS'>{page.title}</Typography>
                  {page.links.map((link: any, index: number) => (
                    <RedirectLink key={index} to={`/config/${page.url}${link.link}`}>
                      <a>
                        <Typography
                          variant='bodySM'
                          style={{ textTransform: 'capitalize' }}
                        >
                          {link.label}
                        </Typography>
                      </a>
                    </RedirectLink>
                  ))}
                </styled.SearchSection>
              ))
            ) : (
              <Typography variant='bodyXS' style={{ textAlign: 'center' }}>
                Nenhum resultado
              </Typography>
            )}
          </styled.SearchResults>
        </Popover>
      </styled.SearchContainer>
    </Grid>
  );
};

export default Configurations;
