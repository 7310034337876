import styled from 'styled-components';
import { Grid } from '@l_ine/core';
import { colorPalette } from '@l_ine/tokens';

export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: auto;
`;

export const AvatarGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  //align-items: center;
`;

export const Avatar = styled.div`
  min-width: 52px;
  min-height: 52px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: ${colorPalette.neutral[100]};
  color: ${colorPalette.neutral[0]};
  border-radius: 16px;
`;

export const PartnerLink = styled.div`
  display: flex;
  align-items: center;
  z-index: 25;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
