import styled from 'styled-components';

export const PageHeaderStyle = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 24px;

  .action-buttons {
    display: flex;
    button + button {
      margin-left: 8px;
    }
  }
`;