import { Grid, List } from '@l_ine/core';
import { objectKeys } from '@l_ine/utils';
import Link from 'next/link';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { FieldMonthlyQuarter } from '../';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import ResultsActions from '../../redux/results/actions';
import ExtraContent from './extraContent';
import { ResponseFilterContent } from './filterContent';
import type { NPSFilters, NPSProps } from './index.types';
import ListContent from './listContent';

const NPS: React.FC<NPSProps> = ({
  itemType,
  date,
  tipoCliente,
  quarterInitialValue,
  company,
  user,
}) => {
  const nps = useTypedSelector(({ Results }) => Results.nps);
  const dispatch = useDispatch();

  const [initialDate, setInitialDate] = React.useState(date.initialDate);
  const [finalDate, setFinalDate] = React.useState(date.finalDate);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState<NPSFilters>({});

  const total = nps.extra.detratores + nps.extra.neutros + nps.extra.promotores;

  React.useEffect(() => {
    if (itemType === 'comentario') {
      setFilters({
        infiniteScroll: true,
        page: 1,
        cliente: tipoCliente,
        tipo: itemType, // referente a promotor, neutro ou detrator, além de ter informação de em qual modal está
        comentario: true,
      });
    } else {
      setFilters({
        infiniteScroll: true,
        page: 1,
        cliente: tipoCliente,
        tipo: itemType,
        empresa: company ? company : undefined,
        usuario: user ? user : '',
      });
    }
  }, []);

  React.useEffect(() => {
    if (objectKeys(filters).length > 1) {
      dispatch(
        fetchNPS(
          {
            data_final: finalDate,
            data_inicial: initialDate,
            ...filters,
          },
          setLoading
        )
      );
    }
  }, [filters, initialDate, finalDate]);

  const handleClearFilter = () => {
    setFilters({
      cliente: tipoCliente,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form
          onSubmit={() => {}}
          render={(formProps) => {
            return (
              <form onSubmit={formProps.handleSubmit} noValidate>
                <Grid item xs={12}>
                  <div style={{ height: 'calc(80vh - 150px)' }}>
                    <List
                      data={nps.data}
                      meta={nps.meta}
                      data-testid='resultados--nps--modal-nps--list'
                      loading={loading}
                      options={{
                        searchable: true,
                        showPaper: false,
                        infiniteScroll: {
                          currentOffset: nps.data.length ?? 0,
                          onFetch: (currentOffset?: number) => {
                            if (currentOffset! < total) {
                              setFilters((current) => ({
                                ...current,
                                page: current.page! + 1,
                              }));
                            }
                          },
                        },
                        beforeItemsComponent: (
                          <ExtraContent
                            {...nps.extra}
                            itemType={itemType}
                            filters={filters}
                            setFilters={setFilters}
                            total={total}
                          />
                        ),
                        listItem: (row: any, index: number) => ({
                          customInfoComponent: (
                            <ListContent
                              {...row}
                              index={index}
                              itemType={itemType}
                            />
                          ),
                        }),
                      }}
                      searchOptions={{
                        inputProps: {
                          'data-testid':
                            'resultados--nps--modal-nps--list--field-busca',
                        },
                        placeholder: 'Buscar por clientes',
                        onSearchTextUpdate: (text: string) => {
                          setFilters((current) => ({
                            ...current,
                            page: 1,
                            search: text,
                          }));
                        },
                      }}
                      headerOptions={{
                        size: 'md',
                        customSpaces: {
                          right: (
                            <Grid
                              container
                              spacing={1}
                              style={{ justifyContent: 'flex-end' }}
                            >
                              {itemType === 'all' && (
                                <Grid item xs={6}>
                                  <ResponseFilterContent
                                    setFilters={setFilters}
                                    filters={filters}
                                  />
                                </Grid>
                              )}
                              <Grid
                                item
                                xs={itemType === 'all' ? 6 : 12}
                                style={{ justifyContent: 'flex-end' }}
                              >
                                <FieldMonthlyQuarter
                                  view='quarter'
                                  setInitialDate={setInitialDate}
                                  setFinalDate={setFinalDate}
                                  initialDate={initialDate}
                                  finalDate={finalDate}
                                  formProps={formProps}
                                  testid='resultados--nps--modal-nps--list--field-periodo'
                                  size='md'
                                  quarterInitialValue={quarterInitialValue}
                                />
                              </Grid>
                            </Grid>
                          ),
                        },
                      }}
                    />
                  </div>
                </Grid>
              </form>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

const { fetchNPS } = ResultsActions;

export default NPS;
