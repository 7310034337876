import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
`;

export const commonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
