import {
  Chip,
  colorPalette,
  Divider,
  Grid,
  LIcon,
  Paper,
  Tooltip,
  Typography,
} from '@l_ine/core';
import { useWindowSize } from '@l_ine/hooks';
import { lineChevronDown, lineChevronRight } from '@l_ine/regular-svg-icons';
import React, { useState } from 'react';
import * as styled from './index.style';
import type { AccordionProps } from './index.types';

const Accordion: React.FC<AccordionProps> = ({
  setOpenModal,
  testid,
  focusRef,
  validate: {
    titleIcon,
    title,
    titleValue,
    chipValue,
    chipIcon,
    chipColor,
    chipsView,
    secondChipValue,
    secondChipIcon,
    secondChipColor,
    bodyItem,
  },
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const ref = React.useRef<any>();

  React.useEffect(() => {
    if (focusRef) {
      setExpanded(true);
      if ((title == 'NPS' || title == 'VRM') && ref && ref.current) {
        ref.current.scrollIntoView();
      }
    }
  }, [focusRef, ref]);

  const BodyComponent = ({
    clickable,
    bodyIconColor,
    bodyIcon,
    bodyTitle,
    bodySubtitle,
    bodyDescription,
    bodyChipValue,
    bodyChipIcon,
    bodyChipColor,
    index,
  }: any) => {
    const ref = React.useRef<any>();

    React.useEffect(() => {
      if (title == 'EAC' || title == 'EMC') {
        if (focusRef && ref && ref.current && index == 2) {
          ref.current.scrollIntoView({ block: 'start' });
        }
      }
    }, [ref]);

    const handleOpenModal = () => {
      if (clickable) {
        setOpenModal({ open: true, type: title, id: index });
      }
    };

    return (
      <styled.ItemBody
        clickable={clickable}
        onClick={handleOpenModal}
        data-testid={`accordion--item[${index}]`}
        ref={ref}
      >
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
            <LIcon
              icon={bodyIcon}
              size='48px'
              color={bodyIconColor}
              style={{ marginBottom: '3em', marginRight: '0.5em' }}
            />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='titleMD'>{bodyTitle}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='bodySM' style={{ fontWeight: 'bolder' }}>
                  {bodySubtitle}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='bodySM'>{bodyDescription}</Typography>
              </Grid>
              <Grid item xs={12}>
                {chipsView && bodyChipColor && (
                  <Tooltip
                    arrow={true}
                    label={
                      bodyChipValue == 0
                        ? 'Sem variação com o período anterior'
                        : 'Comparação com o período anterior'
                    }
                  >
                    <span>
                      <Chip
                        data-testid={`accordion--itens--chip-${index}`}
                        label={bodyChipValue}
                        icon={bodyChipIcon}
                        color={bodyChipColor}
                        size='sm'
                        style={{ width: '90px' }}
                      />
                    </span>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </styled.ItemBody>
    );
  };

  const renderItens = () => {
    return bodyItem.map((it: any, index: number) => {
      return (
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          key={index}
          style={{
            display: 'grid',
            justifyContent: 'flex-start',
          }}
        >
          <BodyComponent {...it} index={index} />
        </Grid>
      );
    });
  };

  const { width } = useWindowSize();

  const justifyContent = React.useMemo(
    () => ((width ?? 0) >= 600 ? 'flex-end' : 'center'),
    [width]
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper shadow={200} padding={32}>
            <div
              ref={ref}
              onClick={() => setExpanded(!expanded)}
              data-testid={testid}
            >
              <Grid
                container
                spacing={2}
                style={{ alignItems: 'center', cursor: 'pointer' }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <LIcon
                    icon={titleIcon}
                    size='3em'
                    color={`${colorPalette.neutral[800]}`}
                    style={{ paddingRight: '1em' }}
                  />
                  <Typography variant='titleLG'>
                    {title}: {titleValue}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    spacing={2}
                    style={{ display: 'flex', justifyContent }}
                  >
                    {chipsView && (
                      <>
                        <Grid item>
                          <Tooltip label='Comparativo com o trimestre anterior'>
                            <span>
                              <Chip
                                data-testid='accordion--chip'
                                label={chipValue}
                                icon={chipIcon}
                                color={chipColor}
                                size='md'
                                style={{ width: '9em' }}
                              />
                            </span>
                          </Tooltip>
                        </Grid>

                        <Grid item>
                          <Tooltip label='Meta do trimestre atual'>
                            <span>
                              <Chip
                                data-testid='accordion--secondChip'
                                label={secondChipValue}
                                icon={secondChipIcon}
                                color={secondChipColor}
                                size='md'
                                style={{ width: '9em', color: '#fff' }}
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={1}>
                  <Grid container>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent }}>
                      <LIcon
                        icon={expanded ? lineChevronDown : lineChevronRight}
                        size='48px'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            {expanded && (
              <Grid item xs={12}>
                <Divider style={{ marginTop: '32px' }} />
                <Grid
                  container
                  spacing={5}
                  style={{
                    marginTop: '16px',
                    marginLeft: '2vw',
                  }}
                >
                  {renderItens()}
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
export default Accordion;
