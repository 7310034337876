import { Paper } from '@l_ine/core';
import { colorPalette } from '@l_ine/tokens';
import styled from 'styled-components';
import theme from '../../config/themes/light';
interface StylesProps {
  bgColor?: string;
  shadow?: string | number;
  height: string | number;
  border?: string;
  fontSize?: string | number;
  color?: string;
  width?: string;
}

export const Wrapper = styled(Paper)<StylesProps>`
  //box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.03);
  padding: 8px;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : colorPalette.neutral[0]};
  border-radius: 6px;
  height: ${(props) => props.height};
  border: ${(props) => (props.border ? props.border : 'none')};
  color: ${(props) => (props.color ? props.color : colorPalette.neutral[900])};
  width: ${(props) => (props.width ? props.width : '')};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.span<StylesProps>`
  color: ${(props) => (props.color ? props.color : theme.palette.text.primary)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16pt')};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;
