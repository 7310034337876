import React from 'react';

import { useRouter } from 'next/router';

import NPSContent from './npsContent/npsContent';

import { NpsPayload } from '../../../redux/results/types';

const ListContent: React.FC<NpsPayload & { itemType: string; index: number }> = ({
  nota,
  index,
  nomeUsu,
  itemType,
  emailUsu,
  comentario,
  tipoPessoa,
  razaoSocial,
  nomeFantasia,
  dataAvaliacao,
  quantidadeCliques,
  quantidadeDisparos,
  codigoEmpresa,
}) => {
  const _router = useRouter();

  const _handleClick = React.useCallback(() => {
    _router.push(`/clientes/perfil?id=${codigoEmpresa}`);
  }, []);

  return (
    <div style={{ cursor: 'pointer', width: '100%' }} onClick={_handleClick}>
      <NPSContent
        nota={nota}
        razaoSocial={razaoSocial}
        nomeFantasia={nomeFantasia}
        tipoPessoa={tipoPessoa}
        index={index}
        dataAvaliacao={dataAvaliacao}
        quantidadeCliques={quantidadeCliques}
        quantidadeDisparos={quantidadeDisparos}
        nomeUsu={nomeUsu}
        emailUsu={emailUsu}
        comentario={comentario}
      />
    </div>
  );
};

export default ListContent;
