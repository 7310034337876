export const filterOptionsClients = [
  {
    key: 0,
    label: 'Clientes',
    value: 'clientes',
    'data-testid': 'gaps--field-cliente[0]'
  },
  {
    key: 1,
    label: 'Parceiro',
    value: 'parceiros',
    'data-testid': 'gaps--field-cliente[1]'
  },
  {
    key: 2,
    label: 'Clientes e parceiros',
    value: 'clientesparceiros',
    'data-testid': 'gaps--field-cliente[2]'
  },
]

export const filterOptionsMethod = [
  {
    key: 0,
    label: 'GET',
    value: 'GET',
    'data-testid': 'gaps--field-metodo[0]'
  },
  {
    key: 1,
    label: 'POST',
    value: 'POST',
    'data-testid': 'gaps--field-metodo[1]'
  },
  {
    key: 2,
    label: 'PUT',
    value: 'PUT',
    'data-testid': 'gaps--field-metodo[2]'
  },
  {
    key: 3,
    label: 'PATCH',
    value: 'PATCH',
    'data-testid': 'gaps--field-metodo[3]'
  },
  {
    key: 4,
    label: 'DELETE',
    value: 'DELETE',
    'data-testid': 'gaps--field-metodo[4]'
  },
  {
    key: 5,
    label: 'TODOS',
    value: 'TODOS',
    'data-testid': 'gaps--field-metodo[5]'
  }
]