import {
  lineHandshake,
  lineReceipt,
  lineEdit,
  lineCog,
  lineCalendarStar,
  lineHorn,
  lineUser,
} from '@l_ine/light-svg-icons';

export const configurationPages = [
  {
    key: 0,
    icon: lineEdit,
    name: 'plansData',
    title: 'Planos e recursos',
    links: [
      { label: 'Cadastro e edição', link: '?tab=planos' },
      { label: 'recursos dos planos', link: '?tab=recursos' },
      { label: 'grupos', link: '?tab=planos' },
    ],
    url: 'dados-planos',
  },
  {
    key: 1,
    icon: lineHandshake,
    name: 'partnerSettings',
    title: 'Tipos de parceiros',
    links: [
      { label: 'Cadastrar tipo de parceiro', link: '' },
      { label: 'funções de parceiros', link: '' },
      { label: 'ativar e inativar parceiro', link: '' },
    ],
    url: 'dados-parceiros',
  },
  {
    key: 2,
    icon: lineCog,
    name: 'initialSetup',
    title: 'Setup inicial',
    links: [
      { label: 'Aceitar parceiro', link: '?tab=convite' },
      { label: 'aceitar CNPJ/CPF nos convites', link: '?tab=convite' },
    ],
    url: 'setup-inicial',
  },
  {
    key: 3,
    icon: lineCalendarStar,
    name: 'news',
    title: 'Cadastro de novidades',
    links: [{ label: 'Informe novidades do sistema para seus usuários', link: '' }],
    url: 'news',
  },
  {
    key: 4,
    icon: lineHorn,
    name: 'notifications',
    title: 'Cadastro de notificações',
    links: [
      { label: 'Cadastre notificações do sistema para seus usuários', link: '' },
    ],
    url: 'notifications',
  },
  {
    key: 5,
    icon: lineUser,
    name: 'users',
    title: 'Usuários',
    links: [{ label: 'Permite ou bloqueie usuários do sistema', link: '' }],
    url: 'users',
  },
];
