import { Chip, colorPalette, Grid, LIcon, Typography } from '@l_ine/core';
import { lineCopy } from '@l_ine/light-svg-icons';
import { lineExternalLink } from '@l_ine/solid-svg-icons';
import moment from 'moment';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import type { ErrorRow } from '../index.types';
import { ExternalActionButton, GridCopy } from './index.style';

const DetailsContent: React.FC<ErrorRow> = ({
  usuario,
  data,
  parceiro,
  razao_social,
  nome_fantasia,
  status_code,
  mensagem,
  cod_empresa,
  endpoint,
  metodo,
  ip,
  sentry_url,
}) => {
  const sistema = useSelector((state: any) => state.Auth.sistema);

  const handleOpenHotjar = (data: string, empresa: string) => {
    const initDate = moment(data).unix();
    const endDate = moment(data).add(1, 'days').unix();

    const jsonHotjar = {
      AND: [
        {
          RANGE_IN: {
            created: [initDate, endDate],
          },
        },
        {
          EQUAL: {
            tag_names: empresa,
          },
        },
      ],
    };

    const filterHotjar = window.encodeURIComponent(JSON.stringify(jsonHotjar));
    window.open(
      `https://insights.hotjar.com/sites/${sistema.id_hotjar}/playbacks/list?filters=${filterHotjar}`
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
              com {usuario} {moment(data).fromNow()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {!!parceiro && (
              <Chip
                label='Parceiro'
                color={colorPalette.neutral[100]}
                style={{
                  color: colorPalette.neutral[0],
                  marginRight: '0.3em',
                }}
                size='sm'
                data-testid={`gaps--datatable--modal-detalhes--chip-parceiro`}
              />
            )}
            {!!nome_fantasia && nome_fantasia != razao_social && (
              <Typography variant='titleXS' style={{ textTransform: 'capitalize' }}>
                {nome_fantasia.toLowerCase()}
              </Typography>
            )}
          </Grid>
          {typeof razao_social === 'string' && (
            <Grid item xs={12}>
              <Typography variant='titleXS'>{razao_social.toUpperCase()}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='titleXS'>Erro</Typography>
          </Grid>
          <GridCopy item xs={12}>
            <Typography variant='bodySM'>
              {status_code} - {mensagem}
            </Typography>
            <CopyToClipboard text={`${status_code} - ${mensagem}`}>
              <LIcon
                icon={lineCopy}
                data-testid='gaps--datatable--modal-detalhes--icon-copiarMensagem'
                style={{ cursor: 'pointer' }}
              />
            </CopyToClipboard>
          </GridCopy>

          <Grid item xs={12}>
            <Typography variant='titleXS'>Emp. cod.</Typography>
          </Grid>
          <GridCopy item xs={12}>
            <Typography variant='bodySM'>{cod_empresa}</Typography>
            <CopyToClipboard text={`${cod_empresa}`}>
              <LIcon
                icon={lineCopy}
                data-testid='gaps--datatable--modal-detalhes--icon-copiarCodigo'
                style={{ cursor: 'pointer' }}
              />
            </CopyToClipboard>
          </GridCopy>

          <Grid item xs={12}>
            <Typography variant='titleXS'>Caminho</Typography>
          </Grid>
          <GridCopy item xs={12}>
            <Typography variant='bodySM'>{endpoint}</Typography>
            <CopyToClipboard text={`${endpoint}`}>
              <LIcon
                icon={lineCopy}
                data-testid='gaps--datatable--modal-detalhes--icon-copiarCaminho'
                style={{ cursor: 'pointer' }}
              />
            </CopyToClipboard>
          </GridCopy>

          <Grid item xs={12}>
            <Typography variant='titleXS'>IP</Typography>
          </Grid>
          <GridCopy item xs={12}>
            <Typography variant='bodySM'>{ip}</Typography>
            <CopyToClipboard text={`${ip}`}>
              <LIcon
                icon={lineCopy}
                data-testid='gaps--datatable--modal-detalhes--icon-copiarIp'
                style={{ cursor: 'pointer' }}
              />
            </CopyToClipboard>
          </GridCopy>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <ExternalActionButton
              data-testid='gaps--interacoesNegativas--button-hotjar'
              onClick={() => handleOpenHotjar(data, razao_social)}
            >
              <LIcon
                icon={lineExternalLink}
                color={colorPalette.neutral[100]}
                removeMargin
              />
              <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
                <u>Hotjar</u>
              </Typography>
            </ExternalActionButton>
            {sentry_url ? (
              <ExternalActionButton
                data-testid='gaps--interacoesNegativas--button-sentry'
                onClick={() => {
                  window.open(sentry_url);
                }}
              >
                <LIcon
                  icon={lineExternalLink}
                  color={colorPalette.neutral[100]}
                  removeMargin
                />
                <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
                  <u>Sentry</u>
                </Typography>
              </ExternalActionButton>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsContent;
