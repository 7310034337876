import styled from 'styled-components';
import { Grid, LIcon } from '@l_ine/core';
import { colorPalette } from '@l_ine/tokens';
import theme from '../../config/themes/light';

interface StylesProps {
  iconColor?: string;
}

export const Wrapper = styled.div`
  background-color: ${colorPalette.neutral[10]};
  border-radius: 2px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.06);
`;

export const TitleDescriptionGrid = styled(Grid)`
  padding: 1rem 0rem;
`;

export const TitleGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DescriptionGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconGrid = styled(Grid)`
  background-color: ${colorPalette.neutral[900]};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
`;

export const Icon = styled(LIcon)<StylesProps>`
  color: ${(props) =>
    props.iconColor ? props.iconColor : theme.palette.secondary.color};
`;
