import { Typography } from '@l_ine/core';
import Router from 'next/router';
import React, { useRef } from 'react';
import * as styled from './index.style';
import type { CardProps } from './index.types';

const Card: React.FC<CardProps> = (props) => {
  const { page } = props;
  const linksRef = useRef<any>(null);

  const handleClick = (e: any) => {
    if (linksRef.current && linksRef?.current?.contains(e.target)) return;
    Router.push(`/config/${page.url}`);
  };

  return (
    <styled.Container onClick={handleClick} data-testid={props.testid}>
      <styled.Wrapper>
        <styled.Icon icon={page.icon} size='32px' />
        <styled.TextContainer>
          <styled.TitleGrid>
            <Typography variant='titleXS'>{page.title}</Typography>
          </styled.TitleGrid>
          <styled.DescriptionGrid>
            {page.links &&
              page.links.map((link: any, index: number, arr: any) => {
                if (arr.length - 1 === index) {
                  return (
                    <Typography key={index} variant='bodyXS'>
                      {link.label}
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      key={index}
                      variant='bodyXS'
                    >{`${link.label}, `}</Typography>
                  );
                }
              })}
          </styled.DescriptionGrid>
        </styled.TextContainer>
      </styled.Wrapper>
    </styled.Container>
  );
};

export default Card;
