import React from 'react';

import { CircularProgress } from '@l_ine/core';

import * as styled from './index.style';

const Loading: React.FC = () => {
  return (
    <styled.Wrapper>
      <styled.PaperLoading>
        <CircularProgress size={50} color='primary' />
      </styled.PaperLoading>
    </styled.Wrapper>
  );
};

export default Loading;
