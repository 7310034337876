import { Button, LIcon, Typography } from '@l_ine/core';
import { linePlus } from '@l_ine/solid-svg-icons';
import React from 'react';
import * as styled from './index.style';
import type { PageHeaderProps } from './index.types';

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  primaryButtonClick,
  primaryButtonIcon,
  primaryButtonTitle,
  secondaryButtonClick,
  secondaryButtonTitle,
  secondaryButtonIcon,
  metas,
}) => (
  <styled.PageHeaderStyle>
    <Typography variant='titleMD' noWrap>
      {title}
    </Typography>
    <div className='action-buttons'>
      {!!secondaryButtonTitle && (
        <Button
          size='md'
          data-testid='pageHeader--button-secundario'
          variant='neutral'
          onClick={secondaryButtonClick}
          startIcon={<LIcon icon={secondaryButtonIcon} size={'24px'} />}
        >
          {secondaryButtonTitle}
        </Button>
      )}
      {(!!primaryButtonTitle || !!metas) && (
        <Button
          size='md'
          data-testid='pageHeader--button-primario'
          variant='contained'
          bgColor='primary'
          onClick={primaryButtonClick}
          startIcon={
            <LIcon
              icon={primaryButtonIcon ? primaryButtonIcon : linePlus}
              size={'24px'}
            />
          }
        >
          {primaryButtonTitle}
        </Button>
      )}
    </div>
  </styled.PageHeaderStyle>
);

export default React.memo(PageHeader);
