import { LIcon, Typography } from '@l_ine/core';
import { lineArrowLeft } from '@l_ine/solid-svg-icons';
import router from 'next/router';
import React from 'react';

export interface HeaderBackButtonProps {
  pathname: string;
  title: string | Record<string, unknown>;
}

const HeaderBackButton: React.FC<HeaderBackButtonProps> = (props) => {
  const { pathname, title } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
      <LIcon
        icon={lineArrowLeft}
        size='24px'
        removeMargin
        data-testid='clientes-perfil--button-voltar'
        onClick={() => router.push(pathname)}
        style={{ cursor: 'pointer' }}
      />
      <Typography variant='titleMD'>{title}</Typography>
    </div>
  );
};

export default HeaderBackButton;
