import { Field, FormFooter, Grid } from '@l_ine/core';
import React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import type { NPSFilters } from '../index.types';
import { assembleForm, assembleQuery } from './helper';
import {
  filterOptionsCategoria,
  filterOptionsComentario,
  filterOptionsIndicacao,
  filterOptionsRespostas,
} from './static';

export const FilterContent = (props: any) => {
  const { filters, setFilters, onClose } = props;

  const onSubmit = (values: any) => {
    setFilters(assembleQuery(values, filters));
    onClose();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form
          onSubmit={onSubmit}
          initialValues={assembleForm(filters)}
          render={(formProps) => {
            return (
              <form onSubmit={formProps.handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name='tipo'
                      placeholder='Selecione a categoria'
                      mode='select'
                      fieldSize='md'
                      options={filterOptionsCategoria}
                      isClearable={true}
                      inputProps={{
                        'data-testid':
                          'resultados--nps--modal-nps--list--modal-filtro--field-tipo',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name='comentario'
                      placeholder='Comentário'
                      mode='select'
                      fieldSize='md'
                      options={filterOptionsComentario}
                      isClearable={true}
                      inputProps={{
                        'data-testid':
                          'resultados--nps--modal-nps--list--modal-filtro--field-comentario',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name='indicacao'
                      placeholder='Indicação'
                      mode='select'
                      fieldSize='md'
                      options={filterOptionsIndicacao}
                      isClearable={true}
                      inputProps={{
                        'data-testid':
                          'resultados--nps--modal-nps--list--modal-filtro--field-indicacao',
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      <FormFooter
                        noBorder
                        primaryButton={{
                          'data-testid':
                            'resultados--nps--modal-nps--list--modal-filtro--button-filtrar',
                          title: 'Aplicar filtros',
                          size: 'md',
                        }}
                        secondaryButton={{
                          'data-testid':
                            'resultados--nps--modal-nps--list--modal-filtro--button-voltar',
                          title: 'Cancelar',
                          size: 'md',
                          onClick: onClose,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export const ResponseFilterContent = (props: any) => {
  const { filters, setFilters } = props;

  return (
    <>
      <Field
        name='respostas'
        mode='select'
        fieldSize='md'
        initialValue={filterOptionsRespostas[1]}
        options={filterOptionsRespostas}
        inputProps={{
          'data-testid': 'resultados--nps--modal--list--field-respostas',
        }}
      />
      <OnChange name='respostas'>
        {(value) => {
          if (value.key === 0) {
            setFilters((current: NPSFilters) => ({
              ...current,
              voto: 'votante',
              tipo: 'all',
              page: 1,
            }));
          } else if (value.key === 1) {
            delete filters.voto;
            setFilters((current: NPSFilters) => ({
              ...current,
              page: 1,
              tipo: 'all',
            }));
          } else if (value.key === 2) {
            setFilters((current: NPSFilters) => ({
              ...current,
              voto: 'nao_votante',
              tipo: 'all',
              page: 1,
            }));
          }
        }}
      </OnChange>
    </>
  );
};
