import { useRouter } from 'next/router';

enum MouseClickID {
  LEFT = 0,
  MIDDLE = 1,
  RIGHT = 2,
}

export default function useRedirectLink(to: string) {
  const _router = useRouter();

  const _redirecionar: React.MouseEventHandler = (ev) => {
    if (ev.button === MouseClickID.LEFT) {
      _router.push(to);
    } else if (ev.button === MouseClickID.MIDDLE) {
      ev.preventDefault();
      window.open(`${window.location.origin}${to}`, '_blank');
    }
  };

  return {
    _redirecionar,
  };
}
