import styled from 'styled-components';

interface Props {
  clickable?: boolean;
}

export const ItemBody = styled.div`
  padding: 8px;
  &:hover {
    cursor: ${(props: Props) => (props && props.clickable ? 'pointer' : 'default')};
    background-color: ${(props: { clickable?: boolean }) =>
      props.clickable ? 'rgba(0, 0, 0, 0.1)' : 'unset !important'};
  }
`;
