import { Button, colorPalette, Grid, LIcon, Typography } from '@l_ine/core';
import { linePlus } from '@l_ine/solid-svg-icons';
import React from 'react';
import { commonStyle, Container } from './index.style';
import type { EmptyStateProps } from './index.types';
import { texts } from './static';

const EmptyState = (props: EmptyStateProps): JSX.Element => {
  const { type } = props;

  const handleClick = () => {
    if (typeof props.onClick === 'function') {
      props.onClick();
    }
  };

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ ...commonStyle }}>
          <Typography variant='titleSM'>{texts.title[type]}</Typography>
        </Grid>
        <Grid item xs={12} style={{ ...commonStyle }}>
          <Typography variant='bodySM' style={{ color: colorPalette.neutral[100] }}>
            {texts.subtitle[type]}
          </Typography>
        </Grid>
        {typeof props.onClick === 'function' && (
          <Grid item xs={12} style={{ ...commonStyle }}>
            <Button
              data-testid='button-emptyState'
              variant='contained'
              size='sm'
              bgColor='primary'
              onClick={handleClick}
              startIcon={<LIcon icon={linePlus} />}
            >
              {texts.button[type]}
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default EmptyState;
