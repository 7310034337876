import { colorPalette, Grid, LIcon, Typography } from '@l_ine/core';
import { useWindowSize } from '@l_ine/hooks';
import {
  lineDollarSignCircle,
  lineUser,
  lineUserFriends,
} from '@l_ine/regular-svg-icons';
import React from 'react';
import * as styled from './index.style';
import type { ExtraContentProps } from './index.types';

const ExtraContent: React.FC<ExtraContentProps> = (props) => {
  const {
    ativos,
    bloqueados,
    clientes,
    inativos,
    parceiros,
    testeGratis,
  } = props.partnersList.extra;

  const total = (): number => {
    if (props.total) return props.total;
    if (props.filter.situacao == 4 || props.filter.situacao == 0) {
      return ativos + inativos;
    }
    return ativos;
  };

  const { width } = useWindowSize();

  return (
    <Grid container>
      <Grid xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <div
            style={{
              margin: '0.5em',
              marginLeft: '1em',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant='titleXXS' color={colorPalette.neutral[100]}>
              {total() || 0}
            </Typography>
            <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
              &nbsp;{total() == 1 ? 'resultado' : 'resultados'}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item sm={4} xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          {props.filter && props.filter.situacao != 0 && (
            <>
              <div
                onClick={() =>
                  props.setFilter((current: any) => ({ ...current, situacao: 1 }))
                }
                style={{
                  marginLeft: '1em',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <styled.Ellipse color={colorPalette.green[300]} />
                <div>
                  <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                    {ativos ? ativos : 0}
                  </Typography>
                  <Typography variant='bodyXS' color={colorPalette.neutral[600]}>
                    &nbsp;Ativos
                  </Typography>
                </div>
              </div>
              <div
                onClick={() =>
                  props.setFilter((current: any) => ({ ...current, situacao: 2 }))
                }
                style={{
                  marginLeft: '1em',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <styled.Ellipse color={colorPalette.red[300]} />
                <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                  {bloqueados ? bloqueados : 0}
                </Typography>
                <Typography variant='bodyXS' color={colorPalette.neutral[600]}>
                  &nbsp;Bloqueados
                </Typography>
              </div>
            </>
          )}
          {props.filter &&
            (props.filter.situacao == 0 || props.filter.situacao == 4) && (
              <div
                onClick={() =>
                  props.setFilter((current: any) => ({ ...current, situacao: 0 }))
                }
                style={{
                  marginLeft: '1em',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <styled.Ellipse color={colorPalette.neutral[100]} />
                <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                  {inativos ? inativos : 0}
                </Typography>
                <Typography variant='bodyXS' color={colorPalette.neutral[600]}>
                  &nbsp;Inativos
                </Typography>
              </div>
            )}
        </div>
      </Grid>
      <Grid item sm={8} xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: (width ?? 0) < 729 ? 'flex-start' : 'flex-end',
          }}
        >
          {!props.hidePartnersAndClients && (
            <>
              <div
                onClick={() =>
                  props.setFilter((current: any) => ({ ...current, perfil: 1 }))
                }
                style={{
                  marginLeft: '1em',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <LIcon icon={lineUserFriends} size='18px' />
                <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                  {parceiros ? parceiros : 0}
                </Typography>
                <Typography variant='bodyXS' color={colorPalette.neutral[600]}>
                  &nbsp;Parceiros
                </Typography>
              </div>
              <div
                onClick={() =>
                  props.setFilter((current: any) => ({ ...current, perfil: 0 }))
                }
                style={{
                  marginLeft: '1em',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <LIcon icon={lineUser} size='18px' />
                <div>
                  <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                    {clientes ? clientes : 0}
                  </Typography>
                  <Typography variant='bodyXS' color={colorPalette.neutral[600]}>
                    &nbsp;Não parceiros
                  </Typography>
                </div>
              </div>
            </>
          )}
          <div
            onClick={() =>
              props.setFilter((current: any) => ({ ...current, teste_gratis: true }))
            }
            style={{
              marginLeft: '1em',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <LIcon icon={lineDollarSignCircle} size='18px' />
            <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
              {testeGratis ? testeGratis : 0}
            </Typography>
            <Typography variant='bodyXS' color={colorPalette.neutral[600]}>
              &nbsp;Em teste grátis
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ExtraContent;
