import React from 'react';
import * as styled from './index.style';
import type { BoxProps } from './index.types';

const Box: React.FC<BoxProps> = ({
  bgColor,
  height,
  border,
  shadow,
  title,
  titleProps = {},
  field = false,
  fieldProps = {},
  button = false,
  buttonProps = {},
  children,
  width,
}) => (
  <>
    <styled.Wrapper bgColor={bgColor} height={height} border={border} width={width}>
      <styled.Body>{children}</styled.Body>
    </styled.Wrapper>
  </>
);

export default Box;
