export const assembleArrayFone = (values: any) => {
  const newArr: any = [];

  if (values.fone_responsavel) {
    newArr.push(values.fone_responsavel);
  }
  if (values.fone1) {
    newArr.push(values.fone1);
  }
  if (values.fone2) {
    newArr.push(values.fone2);
  }
  if (values.fone3) {
    newArr.push(values.fone3);
  }
  if (values.fone4) {
    newArr.push(values.fone4);
  }
  if (values.fone5) {
    newArr.push(values.fone5);
  }

  return [...newArr];
};

export const assembleArrayEmail = (values: any) => {
  const newArr: any = [];

  if (values.email_responsavel) {
    newArr.push(values.email_responsavel);
  }
  if (values.email) {
    newArr.push(values.email);
  }
  if (values.email2) {
    newArr.push(values.email2);
  }
  if (values.email3) {
    newArr.push(values.email3);
  }
  if (values.email4) {
    newArr.push(values.email4);
  }
  if (values.email5) {
    newArr.push(values.email5);
  }

  return [...newArr];
};
