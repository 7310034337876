import {
  colorPalette,
  DataTable,
  Field,
  Grid,
  LIcon,
  ModalDialog,
} from '@l_ine/core';
import { lineCopy } from '@l_ine/light-svg-icons';
import { lineEye, lineSearch } from '@l_ine/solid-svg-icons';
import { objectKeys } from '@l_ine/utils';
import { throttle } from 'lodash';
import moment from 'moment';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch } from 'react-redux';
import { EmptyState, FieldMonthlyQuarter } from '..';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import GapsActions from '../../redux/gaps/actions';
import { Options } from '../../redux/types';
import type { ErrorsProps } from './index.types';
import DetailsContent from './modalContent';
import { filterOptionsClients, filterOptionsMethod } from './static';

const Errors: React.FC<ErrorsProps> = ({
  quarterFilter,
  quarterInitialValue,
  initialDateProps,
  finalDateProps,
  company,
  user,
  testid,
}) => {
  const dispatch = useDispatch();
  const errorsList = useTypedSelector((state) => state.Gaps.errorsList);
  const [modal, setModal] = React.useState<{ open: boolean; values: any }>({
    open: false,
    values: {},
  });

  const [loading, setLoading] = React.useState(false);
  const [initialDate, setInitialDate] = React.useState(
    initialDateProps
      ? initialDateProps
      : quarterFilter
      ? moment().startOf('quarter').format('YYYY-MM-DDTHH:mm:ss-03:00')
      : moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss-03:00')
  );
  const [finalDate, setFinalDate] = React.useState(
    finalDateProps
      ? finalDateProps
      : quarterFilter
      ? moment().endOf('quarter').format('YYYY-MM-DDTHH:mm:ss-03:00')
      : moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss-03:00')
  );

  const [filters, setFilters] = React.useState<Options>({});

  const total = errorsList.extra;

  React.useEffect(() => {
    setFilters({
      page: 1,
      data_final: finalDate,
      data_inicial: initialDate,
      empresa: company ? company : '',
      usuario: user ? user : '',
    });
  }, [initialDate, finalDate]);

  React.useEffect(() => {
    if (objectKeys(filters).length > 0) {
      dispatch(GapsActions.fetchErrors(filters, setLoading));
    }
  }, [filters]);

  const handleSearch = throttle(
    (term) => {
      if (!term) return;
      setFilters((current) => ({ ...current, search: term, page: 1 }));
    },
    500,
    { leading: false }
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Form
          onSubmit={() => {}}
          render={(formProps: any) => {
            return (
              <form onSubmit={formProps.handleSubmit} noValidate>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <DataTable
                      columns={[
                        {
                          label: 'Quando',
                          name: 'data',
                          format: (value: string) =>
                            moment(value).format(`DD/MM/Y HH:mm`),
                          width: '1vw',
                        },
                        {
                          label: 'Erro',
                          name: 'status_code',
                          format: (value: string, values: any) => {
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {value} - {values.mensagem.substring(0, 60)}
                                {values.mensagem.length > 60 ? '...' : ''}
                                <CopyToClipboard text={`${values.mensagem}`}>
                                  <LIcon
                                    icon={lineCopy}
                                    size='18px'
                                    data-testid={`${testid}--datatable--icon-copiarErro`}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </CopyToClipboard>
                              </div>
                            );
                          },
                          width: '15vw',
                        },
                        {
                          label: 'Cliente',
                          name: 'razao_social',
                          format: (value, values) =>
                            `${values.cod_empresa} - ${value}`,
                          width: '12vw',
                        },
                        {
                          label: 'Usuário',
                          name: 'usuario',
                          width: '1vw',
                        },
                        {
                          label: 'Caminho',
                          name: 'endpoint',
                          format: (value) =>
                            `${value.substring(0, 17)}${
                              value.length > 17 ? '...' : ''
                            }`,
                          width: '4vw',
                        },
                        {
                          label: '',
                          name: 'action',
                          format: (_, row) => (
                            <LIcon
                              icon={lineEye}
                              size='32px'
                              color={colorPalette.neutral[900]}
                              onClick={() => setModal({ open: true, values: row })}
                              data-testid={`${testid}--datatable--icon-abrirModal`}
                              style={{ cursor: 'pointer' }}
                            />
                          ),
                          width: '1vw',
                        },
                      ]}
                      data={errorsList.data}
                      meta={errorsList.meta}
                      isLoading={loading}
                      options={{
                        searchable: true,
                        showPaper: false,
                        pagination: true,
                        noDataMessage: <EmptyState type='interactions' />,
                        rowSize: 'lg',
                        hideCount: true,
                        onPageChange: (page: number) =>
                          setFilters((current: any) => ({ ...current, page: page })),
                      }}
                      headerOptions={{
                        size: 'lg',
                        customSpaces: {
                          left: (
                            <Form
                              onSubmit={() => {}}
                              render={() => (
                                <form noValidate>
                                  <div style={{ width: '380px' }}>
                                    <Field
                                      name='search'
                                      placeholder='Buscar por cliente, emp. cod., usuário ou nome de erro'
                                      inputProps={{
                                        'data-testid': `${testid}--field-buscaClienteEmpcodNomeErro`,
                                      }}
                                      type='text'
                                      fieldSize='md'
                                      style={{ width: '100%' }}
                                      startAdornment={<LIcon icon={lineSearch} />}
                                    />
                                    <OnChange name='search'>
                                      {(value) => {
                                        handleSearch(value);
                                      }}
                                    </OnChange>
                                  </div>
                                </form>
                              )}
                            />
                          ),
                          right: (
                            <Grid
                              container
                              spacing={1}
                              style={{ display: 'flex', justifyContent: 'flex-end' }}
                            >
                              <Grid item xs={4}>
                                <Field
                                  name='methodFilter'
                                  size='md'
                                  mode='select'
                                  options={filterOptionsMethod}
                                  placeholder='Método'
                                  inputProps={{
                                    'data-testid': `${testid}--field-metodo`,
                                  }}
                                />
                                <OnChange name='methodFilter'>
                                  {(value) => {
                                    setFilters((current) => ({
                                      ...current,
                                      metodo: value.value,
                                    }));
                                  }}
                                </OnChange>
                              </Grid>
                              <Grid item xs={4}>
                                <Field
                                  name='clientFilter'
                                  size='md'
                                  mode='select'
                                  options={filterOptionsClients}
                                  placeholder='Clientes e parceiros'
                                  inputProps={{
                                    'data-testid': `${testid}--field-metodo`,
                                  }}
                                />
                                <OnChange name='clientFilter'>
                                  {(value) => {
                                    setFilters((current) => ({
                                      ...current,
                                      cliente: value.key,
                                    }));
                                  }}
                                </OnChange>
                              </Grid>
                              <Grid item xs={4}>
                                <FieldMonthlyQuarter
                                  view={quarterFilter ? 'quarter' : 'monthly'}
                                  setInitialDate={setInitialDate}
                                  setFinalDate={setFinalDate}
                                  initialDate={initialDate}
                                  finalDate={finalDate}
                                  formProps={formProps}
                                  testid={`${testid}--field-periodo`}
                                  size='md'
                                  quarterInitialValue={
                                    quarterInitialValue
                                      ? quarterInitialValue
                                      : undefined
                                  }
                                />
                              </Grid>
                            </Grid>
                          ),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
        {modal.open && (
          <ModalDialog
            data-testid='gaps--datatable--modal-detalhes'
            onClose={() => setModal({ open: false, values: {} })}
            open={modal.open}
            size='md'
            closeIcon
            title='Detalhes da interação negativa'
          >
            <DetailsContent {...modal.values} />
          </ModalDialog>
        )}
      </Grid>
    </Grid>
  );
};

export default Errors;
