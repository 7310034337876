export const filterOptionsCategoria = [
  {
    key: 0,
    label: 'Promotor',
    value: 'promotor',
    'data-testid': 'resultados--nps--modal-nps--list--modal-filtro--field-tipo[0]',
  },
  {
    key: 1,
    label: 'Neutro',
    value: 'neutro',
    'data-testid': 'resultados--nps--modal-nps--list--modal-filtro--field-tipo[1]',
  },
  {
    key: 2,
    label: 'Detrator',
    value: 'detrator',
    'data-testid': 'resultados--nps--modal-nps--list--modal-filtro--field-tipo[2]',
  },
];

export const filterOptionsComentario = [
  {
    key: 1,
    label: 'Possui Comentário',
    value: 'possuiComentario',
    'data-testid': 'resultados--nps--modal-nps--list--modal-filtro--field-comentario[0]',
  },
  {
    key: 0,
    label: 'Sem comentário',
    value: 'semComentario',
    'data-testid': 'resultados--nps--modal-nps--list--modal-filtro--field-comentario[1]',
  },
];

export const filterOptionsIndicacao = [
  {
    key: 1,
    label: 'Possui indicação',
    value: 'possuiIndicacao',
    'data-testid': 'resultados--nps--modal-nps--list--modal-filtro--field-indicacao[0]',
  },
  {
    key: 0,
    label: 'Sem indicação',
    value: 'semIndicacao',
    'data-testid': 'resultados--nps--modal-nps--list--modal-filtro--field-indicacao[1]',
  },
];

export const filterOptionsRespostas = [
  {
    key: 0,
    label: 'Apenas respondidas',
    value: 'respondidas',
    'data-testid': 'resultados--nps--modal-nps--list-modal-filtro--field-respostas[0]'
  },
  {
    key: 1,
    label: 'Todas',
    value: 'todas',
    'data-testid': 'resultados--nps--modal-nps--list-modal-filtro--field-respostas[1]'
  },
  {
    key: 2,
    label: 'Apenas não respondidas',
    value: 'naoRespondidas',
    'data-testid': 'resultados--nps--modal-nps--list-modal-filtro--field-respostas[2]'
  },
]