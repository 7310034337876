import React from 'react';

import appActions from '../../redux/app/actions';

import { Notification as LineNotification } from '@l_ine/core';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import { useDispatch } from 'react-redux';

const Alert: React.FC = () => {
  const { snackbar } = useTypedSelector(({ App }) => App);
  const dispatch = useDispatch();

  const { activated, time, title, message, action, actionText } = snackbar;

  const variant = snackbar.variant || 'success';
  const xPosition = snackbar.positionX || 'right';
  const yPosition = snackbar.positionY || 'top';

  const handleClose = () => {
    dispatch(
      appActions.toggleSnackBar({
        activated: false,
        time: 3000,
        variant: 'success',
      })
    );
  };

  return (
    <LineNotification
      data-testid='notification'
      variant={variant}
      autoHideDuration={time}
      title={title}
      message={message}
      open={activated}
      floating
      showCloseButton
      action={action}
      actionText={actionText}
      showActionButton={!!actionText}
      positionX={xPosition}
      positionY={yPosition}
      onClose={() => handleClose()}
    />
  );
};

export default Alert;
