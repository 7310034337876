import formatStringByPattern from 'format-string-by-pattern';
import moment from 'moment';

const numbers = /[\d]+/g;
const specialChars = /[*+?^${%}()|[/\]\\-\s]/g;
const leaveOnlyNums = /[\D]+/g;

type Mask = string | number;

const floatParser = (value: Mask) => {
  if (!value) return value;
  const onlyNums = String(value).replace(/[^\d]/g, '');
  let number = '' + onlyNums;
  if (onlyNums.length <= 2) {
    while (number.length <= 2) {
      number = '0' + number;
    }
  }
  return parseFloat(
    `${number.slice(0, number.length - 2)}.${number.slice(
      number.length - 2,
      number.length
    )}`
  ).toFixed(2);
};

const parseWeight = (value: Mask) => {
  if (!value) return value;
  const onlyNums = String(value).replace(/[^\d]/g, '');
  let number = '' + onlyNums;
  if (onlyNums.length <= 3) {
    while (number.length <= 3) {
      number = '0' + number;
    }
  }
  return parseFloat(
    `${number.slice(0, number.length - 3)}.${number.slice(
      number.length - 3,
      number.length
    )}`
  ).toFixed(3);
};

const parseNumber = (value: Mask) => {
  if (!value) return value;
  const onlyNums = String(value).replace(/[^\d]/g, '');
  return String(Number(onlyNums));
};

const parseDate = (value: string) => {
  if (value.length < 10) {
    return value;
  }
  return moment(value.slice(0, 10), 'DD/MM/YYYY');
};

const parseTime = (value: string) => {
  if (value.length < 4) {
    return value;
  }
  return moment(value.slice(0, 4), 'HH:mm');
};

const formatCurrency = (value: number) => {
  if (!value) return value;
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(value);
};

const formatPercent = (value: number) => {
  if (!value) return value;
  return new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
  }).format(value / 100);
};

const formatPercent2 = (value: number) => {
  if (!value) return value;
  return new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(value / 100);
};

const formatDecimal = (value: number, digits: number = 2) => {
  if (!value) return value;
  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: digits,
  }).format(value);
};

const formatDate = (value: Mask) => {
  if (!value) return value;
  const onlyNums = String(value).replace(/[^\d]/g, '');
  if (onlyNums.length > 8) return moment(value).format('DD/MM/YYYY');
  if (onlyNums.length < 3) return onlyNums;
  if (onlyNums.length < 5) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4,
    8
  )}`;
};

const formatTime = (value: Mask) => {
  if (!value) return value;
  const onlyNums = String(value).replace(/[^\d]/g, '');
  if (onlyNums.length > 4) return moment(value).format('HH:mm');
  if (onlyNums.length < 3) return onlyNums;
  return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)}`;
};

export const plate = {
  format: (value: Mask) => {
    return formatStringByPattern('AAA-9A99', value);
  },
  parse: (value: string) => {
    if (!value) return value;
    if (value.length <= 3)
      return formatStringByPattern('AAA-9A99', value)
        .replace(numbers, '')
        .toUpperCase();
    return formatStringByPattern('AAA-9A99', value)
      .replace(specialChars, '')
      .substring(0, 7)
      .toUpperCase();
  },
};

export const phone = {
  format: (value: Mask) => {
    if (!value) return value;
    const val: string = String(value).replace(/\D/g, '');
    let pattern = '';
    if (val.length <= 10) {
      pattern = '(54) 9999-9999';
    } else if (val.length > 10) {
      pattern = '(54) 9 9999-9999';
    }
    return formatStringByPattern(pattern, val);
  },
  parse: (value: Mask) => {
    if (!value) return value;
    const onlyNums = String(value).replace(/[\D]/g, '').substr(0, 11);
    if (onlyNums) return parseInt(onlyNums);
    return onlyNums;
  },
};

export const zipCode = {
  format: (value: Mask) => {
    return formatStringByPattern('99999-999', value);
  },
  parse: (value: Mask) => {
    if (!value) return value;
    const onlyNums = String(value).replace(/[^\d]/g, '').substr(0, 8);
    return onlyNums;
  },
};

export const cpf = {
  format: (value: Mask) => {
    return formatStringByPattern('999.999.999-99', value);
  },
  parse: (value: Mask) => {
    if (!value) return value;
    const onlyNums = String(value).replace(/[^\d]/g, '').substr(0, 11);
    return onlyNums;
  },
};

export const cnpj = {
  format: (value: Mask) => {
    return formatStringByPattern('53.377.199/0001-38', value);
  },
  parse: (value: Mask) => {
    if (!value) return value;
    const onlyNums = String(value).replace(/[^\d]/g, '').substr(0, 14);
    return onlyNums;
  },
};

export const cpf_cnpj = {
  format: (value: Mask) => {
    if (!value) return value;
    const onlyNums = String(value).replace(/[^\d]/g, '').substr(0, 14);
    let pattern;
    if (onlyNums.length <= 11) {
      pattern = '999.999.999-99';
    } else {
      pattern = '99.999.999/9999-99';
    }
    return formatStringByPattern(pattern, value);
  },
  parse: (value: Mask) => {
    if (!value) return value;
    const onlyNums = String(value).replace(/[^\d]/g, '');
    let size;
    if (onlyNums.length <= 11) {
      size = 11;
    } else {
      size = 14;
    }
    return onlyNums.substring(0, size);
  },
};

export const diasrestantes = {
  format: (value: Mask) => {
    return value + ' dias';
  },
};
export const date = {
  parse: parseDate,
  format: formatDate,
};

export const time = {
  parse: parseTime,
  format: formatTime,
};

export const valor = {
  parse: floatParser,
  format: formatCurrency,
};

export const kg = {
  parse: parseWeight,
  format: (value: number) => formatDecimal(value, 3),
};
export const kg2 = {
  parse: floatParser,
  format: (value: number) => formatDecimal(value, 2),
};

export const valorPerc = {
  parse: floatParser,
  format: formatPercent,
};

export const valorPerc2 = {
  parse: floatParser,
  format: formatPercent2,
};

export const decimal = {
  parse: floatParser,
  format: formatDecimal,
};

export const volume = {
  parse: floatParser,
  // format: formatDecimal
};

export const quantity = {
  parse: floatParser,
  // format: formatDecimal
};

export const quantityRat = {
  parse: floatParser,
  // format: formatDecimal
};

export const ie = {
  parse: (value: string) =>
    value && value.replace(/[\W\_]/g, '').substring(0, 14),
  format: (value: string) =>
    value && value.replace(/[\W\_]/g, '').substring(0, 14),
};

export const rntrc = {
  parse: (value: string) => value && value.replace(/[\D]/g, '').substring(0, 8),
  format: (value: string) =>
    value && value.replace(/[\D]/g, '').substring(0, 8),
};

export const serie = {
  format: (value: Mask) => {
    return formatStringByPattern('999', value);
  },
  parse: (value: Mask) => {
    if (!value) return value;
    const onlyNums = String(value).replace(/[^\d]/g, '').substr(0, 3);
    return onlyNums;
  },
};

export const number = {
  parse: parseNumber,
};

export const renavam = {
  parse: (value: Mask) => {
    if (!value) return value;
    const onlyNums = String(value).replace(/[^\d]/g, '').substr(0, 11);
    return onlyNums;
  },
};

export const formatWith0 = (value: number) => {
  if (!value) return value;
  const onlyNums = String(value).replace(/[^\d]/g, '');
  let number = '' + onlyNums;
  if (onlyNums.length < 2) {
    return '0' + number;
  }
  return value;
}