import {
  colorPalette,
  Field,
  FormFooter,
  Grid,
  LIcon,
  ModalDialog,
  Typography,
} from '@l_ine/core';
import { lineExclamationSquare } from '@l_ine/solid-svg-icons';
import moment from 'moment';
import React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { required } from '../utility/rules';
import * as styled from './index.style';
import type { FieldQuarterProps } from './index.types';

export const filterOptions = [
  {
    key: 0,
    label: 'Desde o início',
    value: 'inicio',
    'data-testid': 'field-periodo[0]',
  },
  {
    key: 1,
    label: '1º Trimestre',
    value: '1trimestre',
    'data-testid': 'field-periodo[1]',
  },
  {
    key: 2,
    label: '2º Trimestre',
    value: '2trimestre',
    'data-testid': 'field-periodo[2]',
  },
  {
    key: 3,
    label: '3º Trimestre',
    value: '3trimestre',
    'data-testid': 'field-periodo[3]',
  },
  {
    key: 4,
    label: '4º Trimestre',
    value: '4trimestre',
    'data-testid': 'field-periodo[4]',
  },
  {
    key: 5,
    label: 'Personalizado',
    value: 'personalizado',
    'data-testid': 'field-periodo[5]',
  },
];

const FieldQuarter: React.FC<FieldQuarterProps> = (props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [personalizado, setPersonalizado] = React.useState(false);
  const [fieldError, setFieldError] = React.useState<string | null>(null);
  const {
    setInitialDate,
    setFinalDate,
    initialDate,
    finalDate,
    size,
    testid,
    placeholder,
  } = props;

  const { form } = props.formProps;

  const handleSubmit = (values: any) => {
    const { fieldInitialDate, fieldFinalDate } = values;
    if (
      moment(fieldInitialDate).format('YYYY/MM/DD') &&
      moment(fieldFinalDate).format('YYYY/MM/DD')
    ) {
      if (
        moment(fieldInitialDate).format('YYYY/MM/DD') >
        moment(fieldFinalDate).format('YYYY/MM/DD')
      ) {
        setFieldError('A data inicial deve ser menor que a data final');
        setTimeout(() => setFieldError(null), 5000);
      } else {
        setFieldError(null);
        setInitialDate(moment(fieldInitialDate).format('YYYY-MM-DDTHH:mm:ss-03:00'));
        setFinalDate(moment(fieldFinalDate).format('YYYY-MM-DDTHH:mm:ss-03:00'));
        setOpenModal(false);
      }
    }
  };

  React.useEffect(() => {
    if (personalizado) {
      form.change('periodo', {
        key: 6,
        label: `${moment(initialDate).format('DD/MM/YYYY')} até ${moment(
          finalDate
        ).format('DD/MM/YYYY')}`,
        value: 'personalizado',
      });
      setPersonalizado(false);
    }
  }, [initialDate, finalDate]);

  const handleBack = () => {
    setOpenModal(false);
    setFieldError(null);
    form.change('periodo', {
      key: 6,
      label: `${moment(initialDate).format('DD/MM/YYYY')} até ${moment(
        finalDate
      ).format('DD/MM/YYYY')}`,
      value: 'personalizado',
    });
  };

  return (
    <>
      <Field
        mode='select'
        name='periodo'
        fieldSize={size}
        placeholder={
          placeholder
            ? placeholder
            : filterOptions[moment(initialDate).utc().quarter()].label
        }
        options={filterOptions}
        inputProps={{
          'data-testid': `${testid}`,
        }}
      />
      <OnChange name='periodo'>
        {(value: any) => {
          if (value && value.key < 5 && value.key > 0) {
            setInitialDate(
              moment()
                .quarter(value.key)
                .startOf('quarter')
                .format('YYYY-MM-DDTHH:mm:ss-03:00')
            ),
              setFinalDate(
                moment()
                  .quarter(value.key)
                  .endOf('quarter')
                  .format('YYYY-MM-DDTHH:mm:ss-03:00')
              );
          } else if (value && value.key == 5) {
            setOpenModal(true);
            setPersonalizado(true);
          } else if (value && value.key == 0) {
            setInitialDate(moment('01/01/2010').format('YYYY-MM-DDTHH:mm:ss-03:00'));
            setFinalDate(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss-03:00'));
          }
        }}
      </OnChange>
      {openModal && (
        <ModalDialog
          data-testid='modal-periodo'
          open={openModal}
          onClose={handleBack}
          size='xs'
          overflow='hidden'
          closeIcon={true}
          disabledBackdropClick={true}
          disabledEscapeKeyDown={true}
          title='Filtrar personalizado'
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Form
                onSubmit={handleSubmit}
                initialValues={null}
                render={(formProps) => {
                  return (
                    <form onSubmit={formProps.handleSubmit} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Field
                            name='fieldInitialDate'
                            label='Data Inicial'
                            mode='datepicker'
                            fieldSize='md'
                            initialValue={moment(initialDate).format('YYYY/MM/DD')}
                            validate={required}
                            required
                            inputProps={{
                              'data-testid':
                                'field-periodo--modal-personalizado--field-dataInicial',
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name='fieldFinalDate'
                            label='Data Final'
                            mode='datepicker'
                            fieldSize='md'
                            initialValue={moment(finalDate).format('YYYY/MM/DD')}
                            validate={required}
                            required
                            inputProps={{
                              'data-testid':
                                'field-periodo--modal-personalizado--field-dataFinal',
                            }}
                          />
                        </Grid>
                        {fieldError && (
                          <styled.ErrorGrid>
                            <Grid container style={{ alignItems: 'center' }}>
                              <LIcon
                                icon={lineExclamationSquare}
                                size='24px'
                                color={colorPalette.red[300]}
                              />
                              <Typography
                                variant='titleXS'
                                style={{
                                  color: colorPalette.red[300],
                                  alignItems: 'center',
                                }}
                              >
                                {fieldError}
                              </Typography>
                            </Grid>
                          </styled.ErrorGrid>
                        )}
                        <Grid item xs={12}>
                          <FormFooter
                            primaryButton={{
                              'data-testid':
                                'field-periodo--modal-personalizado--button-confirmar',
                              title: 'Confirmar',
                              size: 'md',
                              onClick: () => handleSubmit,
                            }}
                            secondaryButton={{
                              'data-testid':
                                'field-periodo--modal-personalizado--button-voltar',
                              title: 'Cancelar',
                              size: 'md',
                              onClick: handleBack,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              />
            </Grid>
          </Grid>
        </ModalDialog>
      )}
    </>
  );
};

export default FieldQuarter;
