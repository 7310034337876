import { Grid, LIcon, Typography } from '@l_ine/core';
import { lineEye, lineTimesCircle } from '@l_ine/solid-svg-icons';

type ConteudopTooltipProps = {
  notAnswered?: boolean;
  quantidadeDisparos: number;
  quantidadeCliques: number;
};

const ConteudoTooltip: React.FC<ConteudopTooltipProps> = ({
  notAnswered,
  quantidadeDisparos,
  quantidadeCliques,
}) => (
  <Grid container spacing={1} style={{ padding: '0.4em', width: '280px' }}>
    <Grid item xs={12}>
      <Typography variant='titleXS'>
        {notAnswered ? 'Pesquisa não respondida pelo usuário' : 'Informações'}
      </Typography>
    </Grid>
    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
      <LIcon icon={lineEye} removeMargin />
      <Typography variant='bodyXS'>
        Apresentada {quantidadeDisparos} {quantidadeDisparos == 1 ? 'vez' : 'vezes'}
      </Typography>
    </Grid>
    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
      <LIcon icon={lineTimesCircle} removeMargin />
      <Typography variant='bodyXS'>
        Clicou para fechar {quantidadeCliques}{' '}
        {quantidadeCliques == 1 ? 'vez' : 'vezes'}
      </Typography>
    </Grid>
  </Grid>
);

export default ConteudoTooltip;
