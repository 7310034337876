import styled from 'styled-components';
import { Grid } from '@l_ine/core';
import { colorPalette } from '@l_ine/tokens';

export const GridCopy = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const ExternalActionButton = styled(Grid)`
  background-color: ${colorPalette.neutral[0]};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
