import {
  colorPalette,
  Field,
  FormFooter,
  Grid,
  LIcon,
  ModalDialog,
  Typography,
} from '@l_ine/core';
import { lineExclamationSquare } from '@l_ine/solid-svg-icons';
import moment from 'moment';
import React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { required } from '../utility/rules';
import * as styled from './index.style';
import type { FieldProps } from './index.types';

export const filterOptionsQuarter = [
  {
    key: 0,
    label: 'Trimestre atual',
    value: 'trimestreAtual',
    'data-testid': 'field-dateFilter[0]',
  },
  {
    key: 1,
    label: 'Trimestre anterior',
    value: 'trimestreAnterior',
    'data-testid': 'field-dateFilter[1]',
  },
  {
    key: 2,
    label: 'Desde o início',
    value: 'inicio',
    'data-testid': 'field-dateFilter[2]',
  },
  {
    key: 3,
    label: 'Personalizado',
    value: 'personalizado',
    'data-testid': 'field-dateFilter[3]',
  },
];

export const filterOptionsMonth = [
  {
    key: 0,
    label: 'Mês atual',
    value: 'mesAtual',
    'data-testid': 'field-dateFilter[0]',
  },
  {
    key: 1,
    label: 'Mês anterior',
    value: 'mesAnterior',
    'data-testid': 'field-dateFilter[1]',
  },
  {
    key: 2,
    label: 'Desde o início',
    value: 'inicio',
    'data-testid': 'field-dateFilter[2]',
  },
  {
    key: 3,
    label: 'Personalizado',
    value: 'personalizado',
    'data-testid': 'field-dateFilter[3]',
  },
];

const FieldMonthlyQuarter: React.FC<FieldProps> = (props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [fieldError, setFieldError] = React.useState<string | null>(null);
  const {
    setInitialDate,
    setFinalDate,
    initialDate,
    finalDate,
    testid,
    view,
    size,
    setQuarterInitialValue,
    quarterInitialValue,
  } = props;

  const [personalizado, setPersonalizado] = React.useState(
    quarterInitialValue == 3 || false
  );
  const { form } = props.formProps;

  React.useEffect(() => {
    if (personalizado) {
      form.change('dateFilter', {
        key: 4,
        label: `${moment(initialDate).format('DD/MM/YYYY')} até ${moment(
          finalDate
        ).format('DD/MM/YYYY')}`,
        value: 'personalizado',
      });
      setPersonalizado(false);
    }
  }, [initialDate, finalDate, personalizado]);

  const handleSubmit = (values: any) => {
    const { fieldInitialDate, fieldFinalDate } = values;
    if (
      moment(fieldInitialDate).format('YYYY/MM/DD') &&
      moment(fieldFinalDate).format('YYYY/MM/DD')
    ) {
      if (
        moment(fieldInitialDate).format('YYYY/MM/DD') >
        moment(fieldFinalDate).format('YYYY/MM/DD')
      ) {
        setFieldError('A data inicial deve ser menor que a data final');
        setTimeout(() => setFieldError(null), 5000);
      } else {
        setFieldError(null);
        setInitialDate(moment(fieldInitialDate).format('YYYY-MM-DDTHH:mm:ss-03:00'));
        setFinalDate(moment(fieldFinalDate).format('YYYY-MM-DDTHH:mm:ss-03:00'));
        setOpenModal(false);
        setPersonalizado(true);
      }
    }
  };

  const handleBack = () => {
    setOpenModal(false);
    setFieldError(null);
    form.change('dateFilter', {
      key: 4,
      label: `${moment(initialDate).format('DD/MM/YYYY')} até ${moment(
        finalDate
      ).format('DD/MM/YYYY')}`,
      value: 'personalizado',
    });
  };

  return (
    <>
      <Field
        mode='select'
        name='dateFilter'
        fieldSize={size}
        placeholder={view == 'monthly' ? 'Mês atual' : 'Trimestre atual'}
        // placeholder={view == 'monthly' ?
        //   filterOptionsMonth[moment(initialDate).utc().month()].label
        //   : filterOptionsQuarter[moment(initialDate).utc().quarter()].label
        // }
        options={view == 'monthly' ? filterOptionsMonth : filterOptionsQuarter}
        inputProps={{
          'data-testid': `${testid}`,
        }}
        initialValue={
          quarterInitialValue && quarterInitialValue !== 3
            ? view == 'monthly'
              ? filterOptionsMonth[quarterInitialValue]
              : filterOptionsQuarter[quarterInitialValue]
            : null
        }
      />
      <OnChange name='dateFilter'>
        {(value: any) => {
          if (value && value.key == 0) {
            if (view === 'monthly') {
              setInitialDate(
                moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss-03:00')
              );
              setFinalDate(
                moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss-03:00')
              );
            } else if (view === 'quarter') {
              setInitialDate(
                moment().startOf('quarter').format('YYYY-MM-DDTHH:mm:ss-03:00')
              );
              setFinalDate(
                moment().endOf('quarter').format('YYYY-MM-DDTHH:mm:ss-03:00')
              );
            }

            setQuarterInitialValue && setQuarterInitialValue(0);
          } else if (value && value.key == 1) {
            if (view === 'monthly') {
              setInitialDate(
                moment()
                  .subtract(1, 'months')
                  .startOf('month')
                  .format('YYYY-MM-DDTHH:mm:ss-03:00')
              );
              setFinalDate(
                moment()
                  .subtract(1, 'months')
                  .endOf('month')
                  .format('YYYY-MM-DDTHH:mm:ss-03:00')
              );
            } else if (view === 'quarter') {
              setInitialDate(
                moment()
                  .subtract(1, 'quarter')
                  .startOf('quarter')
                  .format('YYYY-MM-DDTHH:mm:ss-03:00')
              );
              setFinalDate(
                moment()
                  .subtract(1, 'quarter')
                  .endOf('quarter')
                  .format('YYYY-MM-DDTHH:mm:ss-03:00')
              );
            }

            setQuarterInitialValue && setQuarterInitialValue(1);
          } else if (value && value.key == 2) {
            setInitialDate(moment('01/01/2010').format('YYYY-MM-DDTHH:mm:ss-03:00'));
            setFinalDate(moment().format('YYYY-MM-DDTHH:mm:ss-03:00'));

            setQuarterInitialValue && setQuarterInitialValue(2);
          } else if (value && value.key == 3) {
            setOpenModal(true);
            setPersonalizado(true);
            setQuarterInitialValue && setQuarterInitialValue(3);
          }
        }}
      </OnChange>
      {openModal && (
        <ModalDialog
          open={openModal}
          title='Filtrar personalizado'
          size='xs'
          onClose={handleBack}
          closeIcon={true}
          disabledBackdropClick={true}
          disabledEscapeKeyDown={true}
          overflow='hidden'
          data-testid='modal-dateFilter'
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Form
                onSubmit={handleSubmit}
                initialValues={null}
                render={(formProps) => {
                  return (
                    <form onSubmit={formProps.handleSubmit} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Field
                            name='fieldInitialDate'
                            label='Data inicial'
                            mode='datepicker'
                            fieldSize='md'
                            initialValue={moment(initialDate).format('YYYY/MM/DD')}
                            validate={required}
                            required
                            inputProps={{
                              'data-testid':
                                'field-dateFilter--modal-personalizado--field-dataInicial',
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name='fieldFinalDate'
                            label='Data final'
                            mode='datepicker'
                            direction='vertical'
                            fieldSize='md'
                            initialValue={moment(finalDate).format('YYYY/MM/DD')}
                            validate={required}
                            required
                            inputProps={{
                              'data-testid':
                                'field-dateFilter--modal-personalizado--field-dataFinal',
                            }}
                          />
                        </Grid>
                        {fieldError && (
                          <styled.ErrorGrid>
                            <Grid container style={{ alignItems: 'center' }}>
                              <LIcon
                                icon={lineExclamationSquare}
                                size='24px'
                                color={colorPalette.red[300]}
                              />
                              <Typography
                                variant='titleXS'
                                style={{
                                  color: colorPalette.red[300],
                                  alignItems: 'center',
                                }}
                              >
                                {fieldError}
                              </Typography>
                            </Grid>
                          </styled.ErrorGrid>
                        )}
                        <Grid item xs={12}>
                          <FormFooter
                            primaryButton={{
                              'data-testid':
                                'field-dateFilter--modal-personalizado--button-confirmar',
                              title: 'Confirmar',
                              size: 'md',
                              onClick: () => handleSubmit,
                            }}
                            secondaryButton={{
                              'data-testid':
                                'field-dateFilter--modal-personalizado--button-voltar',
                              title: 'Cancelar',
                              size: 'md',
                              onClick: handleBack,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              />
            </Grid>
          </Grid>
        </ModalDialog>
      )}
    </>
  );
};

export default FieldMonthlyQuarter;
