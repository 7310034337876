import { Typography } from '@l_ine/core';
import moment from 'moment';
import React from 'react';
import type { TooltipDateProps } from './index.types';

const TooltipDateContent: React.FC<TooltipDateProps> = ({
  dataInclusao,
  dataBloqueio,
  dataContratacao,
  dataInativacao,
  motivoBloqueio,
  motivoInativacao,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div>
        {dataInclusao && (
          <Typography variant='bodyXS'>
            Se cadastrou em {moment(dataInclusao).format('DD/MM/YYYY')} ás{' '}
            {moment(dataInclusao).format('HH:mm')}hs
          </Typography>
        )}
      </div>

      <div>
        {dataContratacao ? (
          <Typography variant='bodyXS'>
            Contratou o sistema em {moment(dataContratacao).format('DD/MM/YYYY')} ás{' '}
            {moment(dataContratacao).format('HH:mm')}
            hs
          </Typography>
        ) : (
          <Typography variant='bodyXS'>Ainda não efetuou a contratação</Typography>
        )}
      </div>

      {dataBloqueio && (
        <div style={{ maxWidth: '400px' }}>
          <Typography variant='bodyXS'>
            Bloqueado em {moment(dataBloqueio).format('DD/MM/YYYY')} ás{' '}
            {moment(dataBloqueio).format('HH:mm')}hs
            {!!motivoBloqueio && ` - ${motivoBloqueio}`}
          </Typography>
        </div>
      )}

      {dataInativacao && (
        <div style={{ maxWidth: '400px' }}>
          <Typography variant='bodyXS'>
            Inativado em {moment(dataInativacao).format('DD/MM/YYYY')} ás{' '}
            {moment(dataInativacao).format('HH:mm')}hs
            {!!motivoInativacao && ` - ${motivoInativacao}`}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TooltipDateContent;
