import { ModalDialog } from '@l_ine/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import appActions from '../../redux/app/actions';
import type { DialogProps } from './index.types';

//! foi comentado esse dialog no redux pq a modal tava quebrando
const Dialog: React.FC<DialogProps> = ({
  dialog: { children, activated, onSubmit, dialogProps },
}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(
      appActions.toggleDialog({
        activated: false,
        title: '',
        content: '',
        actions: null,
        onSubmit: null,
        initialValues: null,
        dialogContentProps: {},
      })
    );
  };

  return (
    <ModalDialog
      data-testid='modal-dialog'
      open={activated}
      onClose={() => handleClose()}
      onSubmit={onSubmit}
      {...dialogProps}
    >
      {children}
    </ModalDialog>
  );
};

export default Dialog;
