export const assembleQuery = (values: any, filters: any) => {
    let query: any = {};
    
    if(values.tipo){
        query.tipo = values.tipo.value;
    }
    if(values.comentario){
        query.comentario = Boolean(values.comentario.key)
    }
    if(values.indicacao){
        query.indicacao = Boolean(values.indicacao.key)
    }

    return {
        ...filters,
        ...query
    };
}

export const assembleForm = (values: any) => {
    let form: any = {};

    if(values.tipo){
        if(values.tipo == 'promotor'){
            form.tipo = {
                key: 0,
                label: 'Promotor',
                value: values.tipo
            }
        }
        if(values.tipo == 'neutro'){
            form.tipo = {
                key: 1,
                label: 'Neutro',
                value: values.tipo
            }
        }
        if(values.tipo == 'detrator'){
            form.tipo = {
                key: 2,
                label: 'Detrator',
                value: values.tipo
            }
        }
    }
    if(typeof values.indicacao == 'boolean'){
        form.indicacao = {
            key: values.indicacao ? 1 : 0,
            label: values.indicacao ? 'Possui indicação' : 'Sem indicação',
            value: values.indicacao ? 'possuiIndicação' : 'semIndicação',
        }
    }
    if(typeof values.comentario == 'boolean'){
        form.comentario = {
            key: values.comentario ? 1 : 0,
            label: values.comentario ? 'Possui comentario' : 'Sem comentario',
            value: values.comentario ? 'possuiComentario' : 'semComentario',
        }
    }

    return {
        ...form
    }

}