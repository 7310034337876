import { Chip, Grid, Tooltip } from '@l_ine/core';
import ConteudoTooltip from '../conteudoTootip/conteudoTooltip';

const NaoRespondido: React.FC<{
  quantidadeDisparos: number;
  quantidadeCliques: number;
  index: number;
}> = ({ quantidadeDisparos, quantidadeCliques, index }) => (
  <Grid
    container
    style={{ display: 'flex', alignItems: 'center', padding: '0.5em' }}
  >
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Tooltip
        inverted
        label=' '
        description={
          <ConteudoTooltip
            quantidadeDisparos={quantidadeDisparos}
            quantidadeCliques={quantidadeCliques}
            notAnswered
          />
        }
      >
        <span>
          <Chip
            label='Não respondida'
            color='danger'
            size='sm'
            variant='outlined'
            style={{
              height: '2.2em',
              padding: '0 1em 0 1em',
              justifyContent: 'center',
            }}
            data-testid={`resultados--nps--modal-nps--listItem[${index}]--chip-naoRespondido`}
          />
        </span>
      </Tooltip>
    </Grid>
  </Grid>
);

export default NaoRespondido;
