import styled from 'styled-components';

import { colorPalette } from '@l_ine/tokens';

export const Ellipse = styled.div`
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
  border: 1px solid ${(props) => props.color};
  background-color: ${(props) => props.color};
  margin-right: 4px;
`;
