import styled from 'styled-components';

export const ErrorGrid = styled.div`
  animation: tremulate 0.1s;
  animation-iteration-count: 3;

  @keyframes tremulate {
    0% {
      margin-left: 0;
    }
    25% {
      margin-left: 1.5px;
    }
    50% {
      margin-left: 0;
    }
    75% {
      margin-left: -1.5px;
    }
    100% {
      margin-left: 0;
    }
  }
`;
