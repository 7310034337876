import { Grid, Typography, utils } from '@l_ine/core';
import React from 'react';
import { Form } from 'react-final-form';
import { siteConfig } from '../../config';
import * as styled from './index.style';
import type { LayoutProps } from './index.types';

const loginLogo = 'static/svgs/loginLogo.svg';

const Layout: React.FC<LayoutProps> = ({
  bannerAlt,
  bannerSrc,
  form,
  onSubmit,
  initialValues,
  validate,
  spacing,
  bannerComponent,
}) => {
  const { width } = utils.useWindowSize();
  const footerText = siteConfig.footerText;

  React.useEffect(() => {
    document.body.style.backgroundColor = '#fff';

    return () => {
      document.body.style.backgroundColor = 'rgba(110, 127, 128, 0.05)';
    };
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit} noValidate={true}>
            <Grid
              container
              spacing={spacing ? spacing : 0}
              style={{ height: '100vh' }}
            >
              {width > 960 && (
                <Grid item xs={12} md={6}>
                  <styled.Container>
                    <styled.BannerLogo src={loginLogo} alt='Happier' />
                    <styled.BannerText>
                      Bons produtos
                      <br />
                      oferecendo boas
                      <br />
                      <span>experiências</span>
                    </styled.BannerText>
                    {bannerComponent && React.isValidElement(bannerComponent) ? (
                      React.cloneElement(bannerComponent)
                    ) : (
                      <styled.BannerImg src={bannerSrc} alt={bannerAlt} />
                    )}
                  </styled.Container>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <styled.FormWrapper>
                    {React.cloneElement(form, { ...formProps })}
                  </styled.FormWrapper>
                  <styled.FooterWrapper>
                    <Typography
                      variant='bodySM'
                      noWrap={true}
                      style={{
                        color: 'rgba(75,75,75,0.5)',
                      }}
                    >
                      {footerText}
                    </Typography>
                  </styled.FooterWrapper>
                </div>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default Layout;
